import { template as template_f47e9a72d3a24864af015eb3297b5a1d } from "@ember/template-compiler";
import { get } from "@ember/helper";
import UserFieldConfirm from "./user-fields/confirm";
import UserFieldDropdown from "./user-fields/dropdown";
import UserFieldMultiselect from "./user-fields/multiselect";
import UserFieldText from "./user-fields/text";
const COMPONENTS = {
    confirm: UserFieldConfirm,
    dropdown: UserFieldDropdown,
    multiselect: UserFieldMultiselect,
    text: UserFieldText
};
const UserField = template_f47e9a72d3a24864af015eb3297b5a1d(`
  {{#let (get COMPONENTS @field.field_type) as |Component|}}
    <Component
      @field={{@field}}
      @value={{@value}}
      @validation={{@validation}}
      ...attributes
    />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserField;
