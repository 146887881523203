import { template as template_c4f00192056b4f55b14202e2c2796134 } from "@ember/template-compiler";
const FKLabel = template_c4f00192056b4f55b14202e2c2796134(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
