import { template as template_deef0ec574114fc6881d6af6fa7e14a0 } from "@ember/template-compiler";
const EmptyState = template_deef0ec574114fc6881d6af6fa7e14a0(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
