import { template as template_28d646579669436da7aeeac69006e729 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_28d646579669436da7aeeac69006e729(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
